import ReportViewer, {
	useReporting,
	ReportViewerAPI,
  } from "@qualitas/reportviewer";
  import LanguageSelector from "../../components/Menu/LanguageSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosAPI } from "../../Api/api";
  
  const Reports = (props: any) => {
	const {
	  language,
	  setLanguage,
	  mainColor,
	} = props;

	const location = useLocation();
	const navigate = useNavigate();	

	//const isBackoffice = false

	const api = ReportViewerAPI({ api: AxiosAPI });
	const reportingProps = useReporting({ language, api, navigate, location });
  
	return (
	  <ReportViewer
		{...reportingProps}
		language={language}
		setLanguage={setLanguage}
		mainColor={mainColor}
		LanguageSelector={() => (
		  <LanguageSelector language={language} setLanguage={setLanguage} />
		)}
	  />
	);
  };
  
  export default Reports;
  